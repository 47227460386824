import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUserFav, getUserFavorites } from "../../../services/user";
import {
  setSuccesMessage,
  setErrorMessage,
  setUserData,
  setUserFavourites,
} from "../../../store/features/generalSlice";
import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  ExportIcn,
  ReportOne,
  LoaderAcc,
  Reports,
  ReportTwo,
} from "../../../assets/theme/images/icons";

function AddMyFavourites({itemId, resourceType}) {
  console.log("AddMyFavourites",resourceType);
  const [accessToken, setAccessToken] = useState("");

  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function createUserFavorites(resourceId, type ) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = { resourceId: resourceId, type: type, status: false };
    createUserFav(params, user)
      .then((res) => {
        let succesMessage = "Resource added successfully to the favorites list";

        dispatch(
          setSuccesMessage({
            succesMessage   : succesMessage,
          })
        );
        startFetching();
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          dispatch(
            setErrorMessage({
                errorMessage: error.response.data.message,
            })
          );
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });

    
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let ignore = false;
    const response = await getUserFavorites(user).catch((error) => {
      ignore = true;
    });

    if (!ignore) {
      dispatch(
        setUserFavourites({
          userFavourites: response.data,
        })
      );
    }
  }
  return (
    <Link onClick={() => createUserFavorites(itemId, resourceType)}>
      <Pin />
    </Link>
  );
}

export default AddMyFavourites;

