import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientDashboardTiles,
  setUserData,
} from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import TileAccordion from "./TileAccordion";
import { Link, useNavigate } from "react-router-dom";
import { createUserFav, exportResource } from "../../../services/user";
import ResourceInfoModal from "./ResourceInfoModal";
import Moment from "react-moment";
import "moment-timezone";

import Eye from "../../../assets/theme/images/eye.png";
import Export from "../../../assets/theme/images/export.png";

import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  ExportIcn,
  ReportOne,
  LoaderAcc,
  Reports,
  DashboardTwo,
} from "../../../assets/theme/images/icons";
import { GetDashboardTiles } from "../../../services";

function CustomToggle({
  children,
  eventKey,
  workspaceId,
  clientId,
  resourceId,
}) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    GetDashboardTilesList(resourceId)
  );
  async function GetDashboardTilesList(resourceId) {
    let user = false;
    user = {
      token: userData.accessToken,
    };
    let ignore = false;
    const response = await GetDashboardTiles(resourceId, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      dispatch(
        setClientDashboardTiles({ clientDashboardTiles: response.data })
      );
      setShowLoader(false);
    }
  }

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function DashboardAccordion({ workspaceId = null, clientId = null }) {
  const [name, setName] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const userData = useSelector((state) => state.general.userData);
  //console.log("ReportAccordion", workspaceId, clientId);
  const general = useSelector((state) => state.general);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reportData =
    general?.wholePbiResources && general?.wholePbiResources[workspaceId]
      ? general.wholePbiResources[workspaceId]
      : [];
  //console.log("reportData", reportData);

  const data = reportData.reduce((accumulator, item) => {
    // Get the category of the current item

    const type = item.type;

    // Initialize the category array if not already initialized

    if (!accumulator[type]) {
      accumulator[type] = [];
    }

    // Add the current item to its category array

    accumulator[type].push(item);

    return accumulator;
  }, {});

  // Update the options state
  const clientDashboard = data["Dashboard"];
  function showDescriptionModal(item) {
    console.log("showDescriptionModal");
    setShowModal(true);
    setModalData(item);
  }

  const handleClose = () => setShowModal(false);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function createUserFavorites(resourceId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = { resourceId: resourceId, type: "Dashboard" };
    createUserFav(params, user)
      .then((res) => {
        let succesMessage = "Resource added successfully to the favorites list";
        //localStorage.setItem("alertMessage", succesMessage);
        setAlertMessage(succesMessage);
        //navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  }
  async function exportDashboard(resourceId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = {
      fileFormat: 1,
      resourceType: "Dashboard",
    };
    exportResource(resourceId, params, user)
      .then((res) => {
        let succesMessage = "Export request completed successfully.";
        localStorage.setItem("alertMessage", succesMessage);
        setAlertMessage(succesMessage);
        //navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  }
  return (
    <Accordion defaultActiveKey="">
      {alertMessage && (
        <div className="alert alert-success" role="alert">
          <div className="alert-message">{alertMessage}</div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          <div className="alert-message">{error}</div>
        </div>
      )}
      {clientDashboard &&
        clientDashboard.length > 0 &&
        clientDashboard.map((item) => (
          <Card key={item.id}>
            <Card.Header>
              <CustomToggle eventKey={item.id} resourceId={item.id}>
                <DashboardTwo />
                {item.aliasName ? item.aliasName : item.name}
              </CustomToggle>
              <div className="accordion-actions">
                <span>
                  {item?.lastRefresh?.startTime ? (
                    <Moment tz="Asia/Kolkata">
                      {item?.lastRefresh?.startTime}
                    </Moment>
                  ) : (
                    ""
                  )}
                  {item?.lastRefresh?.status === "Failed" && (
                    <div
                      className="infoIcn"
                      title={
                        JSON.parse(item?.lastRefresh?.serviceExceptionJson)
                          ?.errorCode
                      }
                    >
                      <Info />
                    </div>
                  )}
                </span>
                <div className="buttons">
                  <a href={`/client/embeded-view/${item.id}`}>
                    <img src={Eye} alt="" /> View
                  </a>
                </div>
                <div className="icons">
                  <Link onClick={() => showDescriptionModal(item)}>
                    <Info />
                  </Link>
                  <Link onClick={() => createUserFavorites(item.id)}>
                    <Pin />
                  </Link>
                  {/* <Link onClick={() => exportDashboard(item.id)}>
                    <ExportIcn />
                  </Link> */}
                  {/* <a href="#">
                    <Edit />
                  </a>
                  <a href="#">
                    <TrashTwo />
                  </a> */}
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={item.id}>
              <Card.Body className="page-accordion-wrap">
                <TileAccordion />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      {clientDashboard && clientDashboard.length <= 0 && !showLoader && (
        <Card>
          <Card.Body className="page-accordion-wrap">
            No Result found!
          </Card.Body>
        </Card>
      )}
      <ResourceInfoModal
        show={showModal}
        handleClose={handleClose}
        modalData={modalData}
      />
    </Accordion>
  );
}

export default DashboardAccordion;
