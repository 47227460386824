import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { GetReportPages } from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import LoaderComponent from "../../../components/user/LoaderComponent";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import Resources from "../../../assets/theme/images/action-5.svg";
import SearchBlack from "../../../assets/theme/images/search-black.png";

function ClientReportPages({ instance }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [reports, setReports] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [dashboards, setDashboards] = useState(null);
  const { reportId } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "workspaces" }));
  console.log(reportId);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await GetReportPages(reportId, user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/user/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      setFilteredData(response.data);
      setShowLoader(false);
    }
  }

  const clearSearch = () => {
    setSearchValue("");
    setFilteredData(data);
  };
  const itemSearch = () => {
    const lowercasedFilter = searchValue.toLowerCase();
    const tempData = data.filter((item) => {
      return Object.keys(item).some((key) =>
        typeof item[key] !== "boolean" && typeof item[key] !== "object"
          ? item[key].toLowerCase().includes(lowercasedFilter)
          : ""
      );
    });
    setFilteredData(tempData);
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-list-pg clearfix">
            <div className="title-top-wraper1">
              <h1>Client Report Pages</h1>
              {alertMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-message">{alertMessage}</div>
                </div>
              )}
            </div>
            <div className="main-bg-wraper--1">
              <div className="top--section1">
                <h4></h4>
                <div className="right-btns-row">
                  <div className="search-type-1">
                    <img src={SearchBlack} alt="" />
                    <input
                      placeholder="Search"
                      type="text"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className="btngroup1">
                    <button
                      className="button-new1 blue"
                      onClick={() => itemSearch()}
                    >
                      Filter
                    </button>
                    <button
                      className="button-new1 grey"
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="listing-table-wraper">
                <div className="table-list-items">
                  <div className="table-responsive">
                    <table className="table table-borderless align-middle table-style-new1">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Alias Name</th>
                          <th >Report Id</th>
                          <th>Report Resource Id</th>
                          <th>Order</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData &&
                          filteredData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.displayName}</td>
                              <td>{item.reportId}</td>
                              <td>{item.reportResourceId}</td>
                              <td>{item.order}</td>

                              <td>
                                <ul className="action-icons-list">
                                  <li>
                                    <Link
                                      to={`/user/paginated-embeded-view/${item.reportId}/${item.id}`}
                                      className="btn btn-primary"
                                    >
                                      <img
                                        className="svg-inline"
                                        src={Resources}
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                  
                                </ul>
                              </td>
                            </tr>
                          ))}
                        {filteredData.length <= 0 && (
                          <tr>
                            <td colSpan="7">
                              <span>No result found</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientReportPages;
