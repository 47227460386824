import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import { searchClients, pbiResourcesRefresh } from "../../../services";
import { Link, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import SearchBlack from "../../../assets/theme/images/search-black.png";
import {
  Graph2,
  Reload2,
  Lock2,
  Users2,
  Edit2,
  QnA,
  Info,
  Pin,
  Edit,
  TrashTwo,
  View,
  ExportIcn,
  ReportOne,
  BurgerMenu,
} from "../../../assets/theme/images/icons";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function ClientList({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const [filteredData, setFilteredData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function pbiRefresh(clientId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    pbiResourcesRefresh(clientId, user)
      .then((res) => {
        let succesMessage =
          "Client pbi resources refresh completed successfully";
        setAlertMessage(succesMessage);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          setShowLoader(false);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }

  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let ignore = false;
    const response = await searchClients(searchValue, user).catch((error) => {
      console.log(error);
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      setFilteredData(response.data);
      setShowLoader(false);
    }
  }

  const itemSearch = () => {
    if (!searchValue || searchValue == "" || searchValue == null) {
      return false;
    }
    const lowercasedFilter = searchValue.toLowerCase();
    const tempData = data.filter((item) => {
      return Object.keys(item).some((key) =>
        key == "email" || key == "name" || key == "website" || key == "clientId"
          ? item[key].toLowerCase().includes(lowercasedFilter)
          : ""
      );
    });
    setFilteredData(tempData);
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();

    return () => {
      ignore = true;
    };
  }, []);

  const clearSearch = () => {
    setSearchValue("");
    setFilteredData(data);
  };

  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-list-pg clearfix">
            <div className="title-top-wraper1">
              <h1>Client List</h1>
              <div className="btn-row-1">
                <Link
                  to={`/client-create`}
                  className="btn btn-primary ms-1 btnstyle1"
                >
                  Add
                </Link>
              </div>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}

            <div className="main-bg-wraper--1">
              <div className="top--section1">
                <h4></h4>
                <div className="right-btns-row">
                  <div className="search-type-1">
                    <img src={SearchBlack} alt="" />
                    <input
                      placeholder="Search"
                      type="text"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className="btngroup1">
                    <button
                      className="button-new1 blue"
                      onClick={() => itemSearch()}
                    >
                      Filter
                    </button>
                    <button
                      className="button-new1 grey"
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="listing-table-wraper">
                <div className="table-list-items">
                  <div className="table-responsive min-h-400">
                    <table className="table table-borderless align-middle table-style-new1">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Website</th>
                          <th>Contact Name</th>
                          <th>Contact Number</th>
                          <th>Contact Email</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name && item.name}</td>
                            <td>{item.website && item.website}</td>
                            <td>{item.contact && item.contact[0].name}</td>
                            <td>{item.contact && item.contact[0].phone}</td>
                            <td>{item.contact && item.contact[0].email}</td>
                            <td>{item?.status ? "Active" : "Inactive"}</td>

                            <td>
                              <ul className="action-icons-list">
                                <li>
                                  <Link to={`/client-update/${item.id}`}>
                                    <Edit2 />
                                  </Link>
                                </li>
                                <li>                                  
                                  <DropdownButton
                                    id="dropdown-basic-button"
                                    className="with-dropdown"
                                    title={<BurgerMenu />}
                                  >
                                    <Dropdown.Item
                                      href={`/client-pbi-config/${item.id}`}
                                    >
                                      Pbi Config
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => pbiRefresh(item.id)}
                                    >
                                      Pbi Refresh
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-security-groups/${item.id}`}
                                    >
                                      Security groups
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-group-map/${item.id}`}
                                    >
                                      Security group User Map
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-user-list/${item.id}`}
                                    >
                                      Users
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-workspaces/${item.id}`}
                                    >
                                      Workspaces
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-workspace-group-map/${item.id}`}
                                    >
                                      Workspace group map
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-logo/${item.id}`}
                                    >
                                      Client Logo
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href={`/client-subscriptions/${item.id}`}
                                    >
                                      Subscriptions
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                        {filteredData?.length <= 0 && (
                          <tr>
                            <td colSpan="5">
                              <span>No result found</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientList;
