import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import {
  getEmbedToken,
  getResourceEmbedToken,
} from "../../services/clientAdmin";
import { createActivityLog } from "../../services/user";
import { getEmbedToken as pbiGetEmbedToken } from "../../services";

import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
  setPowerBIEmbedResource,
} from "../../store/features/generalSlice";

function PowerBIEmbedView({ pbiResource, clientData }) {
  console.log("pbiResource", pbiResource);
  const [accessToken, setAccessToken] = useState("");
  const [report, setReport] = useState();
  const [showLoader, setShowLoader] = useState(true);

  const [userEmail, setUserEmail] = useState("aidi_testemployee_1@dapasol.com");
  const [embedToken, setEmbedToken] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function getPages(embeddedReport) {
    console.log("report", report);
  }
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
      }
    }
    return pbUser;
  }
  let body = {};

  async function getEmbedTokens() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    if (pbiResource.type === "Report") {
      body = {
        datasets: [
          {
            id: pbiResource.details.datasetId,
          },
        ],
        reports: [
          {
            id: pbiResource.details.id,
          },
        ],
        identities: [
          {
            username: userEmail,
            roles: ["Demo"],
            datasets: [pbiResource.details.datasetId],
          },
        ],
      };
    }
    if (pbiResource.type === "Dataset") {
      body = {
        datasets: [
          {
            id: pbiResource.resourceId,
          },
        ],

        identities: [
          {
            username: "aidi_testemployee_1@dapasol.com",
            roles: ["MD"],
            datasets: [pbiResource.resourceId],
          },
        ],
      };
    }
    if (pbiResource.type === "Dashboard") {
      body = {
        datasets: [
          {
            id: pbiResource.id,
          },
        ],

        identities: [
          {
            username: userEmail,
            roles: ["MD", "DEMO"],
            datasets: [pbiResource.resourceId],
          },
        ],
      };
    }
    getResourceEmbedToken(pbiResource.id, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(general?.powerBIEmbedLayoutType);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function getVisualsData() {
    // Get the pages from the report
    await report
      .getPages()
      .then((pages) => {
        // Display information about each page
        pages.forEach(async (page) => {
          console.log(`Page name: ${page.displayName}`);
          console.log(`Page ID: ${page.name}`);
          console.log(`IsActive: ${page.isActive}`);

          const visuals = await page.getVisuals();
          console.log("visuals", visuals);

          visuals.forEach((visual) => {
            console.log("visual", typeof visual);
            console.log(`Visual name: ${visual.name}`);
            console.log(`Visual type: ${visual.type}`);
            console.log(`Visual title: ${visual.layout}`);
          });
        });
      })
      .catch((error) => {
        console.error("Error getting pages:", error);
      });
    // const pages = report.getPages();
    // Retrieve the page that contain the visual. For the sample report it will be the active page
    // let page = pages.filter(function (page) {
    //   return page.isActive;
    // })[0];
    // const visuals = page.getVisuals();
    // console.log("visuals", visuals);
    // console.log(
    //   visuals.map(function (visual) {
    //     return {
    //       name: visual.name,
    //       type: visual.type,
    //       title: visual.title,
    //       layout: visual.layout,
    //     };
    //   })
    // );
  }
  async function addActivityLog(log, logMessage = null) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let message = logMessage;
    if (logMessage == null) {
      message =
        log +
        " - : " +
        (pbiResource.aliasName ? pbiResource.aliasName : pbiResource.name);
      console.log("message", message);
    } 
    const extra =
      log +
      "Name : " +
      (pbiResource.aliasName ? pbiResource.aliasName : pbiResource.name) +
      " - ID: " +
      pbiResource.id +
      " - resourceID: " +
      pbiResource.resourceId;
    let formData = {
      activity: log,
      message: message,
      extra: extra,
    };
    console.log("formData", formData);

    createActivityLog(formData, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(general?.powerBIEmbedLayoutType);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  useEffect(() => {
    let ignore = false;
    if (!report) {
      getUserData();
      getEmbedTokens();
    }
    // else {
    //   getVisualsData();
    // }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="card-body">
      {pbiResource.type === "Report" && (
        <PowerBIEmbed
          embedConfig={{
            type: pbiResource.type.toLowerCase(), // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              layoutType: general?.powerBIEmbedLayoutType,
              customLayout: {
                displayOption: models.DisplayOption.FitToPage,
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  addActivityLog("Report loaded");
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function (embeddedReport) {
                  // Get the pages from the report
                  addActivityLog("Report rendered");
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  addActivityLog("Error on loading Report");
                  console.log(event.detail);
                },
              ],
              [
                "visualClicked",
                async function (embeddedReport) {
                  // Get the pages from the report

                  addActivityLog("Report Visual Clicked",
                    "Report Visual Clicked " +
                      embeddedReport?.detail?.report?.displayName +
                      " -> " +
                      embeddedReport?.detail?.page?.displayName +
                      " -> "+
                    embeddedReport?.detail?.visual?.name
                  );

                  console.log("Report Visual Clicked");
                },
              ],
              [
                "pageChanged",
                async function (embeddedReport) {
                  // Get the pages from the report
                  addActivityLog("Report Page Viewed", "Report Page Viewed -> "+(pbiResource.aliasName ? pbiResource.aliasName : pbiResource.name)+" -> "+embeddedReport?.detail?.newPage?.displayName);
                  console.log("Report Page Viewed");
                },
              ],
            ])
          }
          cssClassName={
            general?.powerBIEmbedLayoutType === 2
              ? "reportClass mob"
              : "reportClass"
          }
          getEmbeddedComponent={(embedObject) => {
            console.log(
              `Embedded object of type "${embedObject.embedtype}" received`
            );

            setReport(embedObject);
            dispatch(
              setPowerBIEmbedResource({ powerBIEmbedResource: embedObject })
            );
          }}
          allowFullScreen={general?.isFullScreen}
          fullscreen={general?.isFullScreen}
        />
      )}
      {pbiResource.type === "Paginated Report" && (
        <PowerBIEmbed
          embedConfig={{
            type: pbiResource.type.toLowerCase(), // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              //layoutType: models.LayoutType.MobilePortrait,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],

              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport, report) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      )}
      {pbiResource.type === "Dashboard" && (
        <PowerBIEmbed
          embedConfig={{
            type: "dashboard", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            pageView:
              general?.powerBIEmbedLayoutType === 1
                ? "FitToPage"
                : general?.powerBIEmbedLayoutType,
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  addActivityLog("Dashboard loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  addActivityLog("Dashboard rendered");
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  addActivityLog("Error on loading Dashboard");
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={
            general?.powerBIEmbedLayoutType === "oneColumn"
              ? "reportClass mob"
              : "reportClass"
          }
          getEmbeddedComponent={(embedObject) => {
            console.log(
              `Embedded object of type "${embedObject.embedtype}" received`
            );

            setReport(embedObject);
            dispatch(
              setPowerBIEmbedResource({ powerBIEmbedResource: embedObject })
            );
          }}
          allowFullScreen={general?.isFullScreen}
          fullscreen={general?.isFullScreen}
        />
      )}
      {pbiResource.type === "Tile" && (
        <PowerBIEmbed
          embedConfig={{
            type: "tile", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              layoutType: general?.powerBIEmbedLayoutType,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport, report) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      )}
      {pbiResource.type === "Dataset" && (
        <PowerBIEmbed
          embedConfig={{
            type: "qna", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            datasetIds: [pbiResource.resourceId],
            embedUrl: pbiResource.details.qnaEmbedURL,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              layoutType: general?.powerBIEmbedLayoutType,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  addActivityLog("Dataset loaded");
                  console.log("Dataset loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  addActivityLog("Dataset rendered");
                  console.log("Dataset rendered");
                },
              ],
              [
                "error",
                function (event) {
                  addActivityLog("Error on loading Dashboard");
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={(embedObject) => {
            console.log(
              `Embedded object of type "${embedObject.embedtype}" received`
            );

            setReport(embedObject);
            dispatch(
              setPowerBIEmbedResource({ powerBIEmbedResource: embedObject })
            );
          }}
          allowFullScreen={general?.isFullScreen}
          fullscreen={general?.isFullScreen}
        />
      )}
    </div>
  );
}

export default PowerBIEmbedView;
