import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateClient, getClient } from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import FormErrorMessage from "../../../components/clientAdmin/FormErrorMessage";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
function ClientUpdate({ instance }) {
  const { clientId } = useParams();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  const saveClient = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    updateClient(clientId, params, user)
      .then((res) => {
        let succesMessage = "Client updated successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-list");
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };
  async function getClientData() {
    setShowLoader(true);
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");

    let ignore = false;
    const response = await getClient(clientId, user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      let resData = response.data;
      resData.status = resData.status ? "Active" : "Inactive";
      setData(resData);

      setShowLoader(false);
    }
  }
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("'Name' must not be empty"),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("'Website' must not be empty"),
    legalEntityType: Yup.string().required(
      "'Legal Entity Type' must not be empty"
    ),
    hostingDomain: Yup.string().required("'Hosting Domain' must not be empty."),
    uniqueSubDomain: Yup.string().required(
      "'Unique Sub Domain' must not be empty"
    ),
    // contact: Yup.array(
    //   Yup.object({
    //     name: Yup.string().required("'Name' must not be empty."),
    //     designation: Yup.string().required("'Designation' must not be empty."),
    //     email: Yup.string().required("'Email' must not be empty."),
    //     phone: Yup.string().required("'Phone' must not be empty."),

    //   })
    // ),
    // address: Yup.array(
    //   Yup.object({
    //     type: Yup.string().required("'Type' must not be empty."),
    //     city: Yup.string().required("'City' must not be empty."),

    //     district: Yup.string().required("'District' must not be empty."),
    //     country: Yup.string().required("'Country' must not be empty."),
    //     pinCode: Yup.string().required("'PinCode' must not be empty."),

    //   })
    // ),
    // social: Yup.array(
    //   Yup.object({
    //     facebook: Yup.string()
    //       .matches(
    //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //         "Enter correct url!"
    //       )
    //       .required("'Facebook' must not be empty."),
    //   twitter: Yup.string().matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     'Enter correct url!'
    // ).required("'Twitter' must not be empty."),

    //   linkedIn: Yup.string().matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     'Enter correct url!'
    // ).required("'LinkedIn' must not be empty."),
    //   instagram: Yup.string().matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     'Enter correct url!'
    // ).  required("'Instagram' must not be empty."),
    // })
    //),
  });
  useEffect(() => {
    let ignore = false;
    getUserData();
    getClientData();
    console.log(data);
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client Update</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={data}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                  values.status = values.status == "Active" ? true : false;
                  await new Promise((r) => setTimeout(r, 500));
                  saveClient(JSON.stringify(values, null, 2));
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Name
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="name"
                                      className="form-control"
                                    />
                                    {errors.name && touched.name ? (
                                      <FormErrorMessage message={errors.name} />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Website
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="website"
                                      className="form-control"
                                    />
                                    {errors.website && touched.website ? (
                                      <FormErrorMessage
                                        touched={touched}
                                        errors={errors}
                                        message={errors.website}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Legal Entity Type
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="legalEntityType"
                                      className="form-control"
                                    />
                                    {errors.legalEntityType &&
                                    touched.legalEntityType ? (
                                      <FormErrorMessage
                                        message={errors.legalEntityType}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Hosting Domain
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="hostingDomain"
                                      className="form-control"
                                    />
                                    {errors.hostingDomain &&
                                    touched.hostingDomain ? (
                                      <FormErrorMessage
                                        message={errors.hostingDomain}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Unique Sub Domain
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="uniqueSubDomain"
                                      className="form-control"
                                    />
                                    {errors.uniqueSubDomain &&
                                    touched.uniqueSubDomain ? (
                                      <FormErrorMessage
                                        message={errors.uniqueSubDomain}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Login Domains
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="loginDomains[0]"
                                      className="form-control"
                                    />
                                    {errors.loginDomains &&
                                    touched.loginDomains ? (
                                      <FormErrorMessage
                                        message={errors.loginDomains}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div>Contact Details</div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Name
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].name"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Designation
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].designation"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Email
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].email"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Phone
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].phone"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div>Address Details</div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Type
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].type"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    City
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].city"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    State
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].state"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    District
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].district"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Country
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].country"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Pincode
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].pinCode"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    PAN
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="pan"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    TAN
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="tan"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    GSTIN
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="gstin"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Facebook
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.facebook"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Twitter
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.twitter"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    LinkedIn
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.linkedIn"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Instagram
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.instagram"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Primary Color
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="primaryColor"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    secondary Color
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="secondaryColor"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-0">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Status
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      name="status"
                                      className="form-control selectStyle1"
                                      as="select"
                                      onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setFieldValue("status", selectedValue);
                                      }}
                                    >
                                      <option key="" value="">
                                        Select Status
                                      </option>

                                      <option key="Active" value="Active">
                                        Active
                                      </option>
                                      <option key="Inactive" value="Inactive">
                                        Inactive
                                      </option>
                                    </Field>
                                    {errors.status && touched.status ? (
                                      <FormErrorMessage
                                        message={errors.status}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client-list`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientUpdate;
