import apiClient from "../api-client";
import {BASE_URL, EXP_URL, PBI_RESOURCE_URL, MOBILE_TOKEN, APPLICATION, contentTypes} from "../apiConfig";


// Base function for GET requests
const get = async (route, { body = {}, user = {} }) => {
  
  let headers = {
    Accept: contentTypes["json"],
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "get",
    mode: 'cors',
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for POST requests
const post = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Opener-Policy': 'same-origin'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "post",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for POST requests
const deleteRequest = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "delete",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for POST requests
const patchRequest = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "patch",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for PUT requests
const put = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "put",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for GET requests
const nonExpGet = async (route, { body = {}, user = {} }) => {
  
  let headers = {
    Accept: contentTypes["json"],
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "get",
    mode: 'cors',
    url: `${BASE_URL}/${route}`,
    headers,
    data: body,
  });
};

// Base function for POST requests
const nonExpPost = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "post",
    url: `${BASE_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for POST requests
const nonExpPut = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "put",
    url: `${BASE_URL}/${route}`,
    headers,
    data: body,
  });
};



const pbiGet = async (route, { body = {}, user = {} }) => {
  
  let headers = {   
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    'Access-Control-Allow-Origin': '*'
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  
  return apiClient({
    method: "get",
    
    url: `${PBI_RESOURCE_URL}/${route}`,
    headers,
    data: body,
  });
};
const pbiPost = async (route, { body = {}, user = {} }) => {
  
  let headers = {   
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    'Access-Control-Allow-Origin': '*'
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  
  return apiClient({
    method: "post",
    
    url: `${PBI_RESOURCE_URL}/${route}`,
    headers,
    data: body,
  });
};

// Routes
const routes = {
  
};

export { routes, get, post, deleteRequest, patchRequest, put, pbiGet, pbiPost, nonExpGet,  nonExpPost, nonExpPut};

export {getClient, searchWorkspaces, searchPbiResources, getPbiResources, getEmbedToken, pbiResourcesRefresh, searchClientSecurityGroups, searchCreatedNotifications, getMyNotifications, getActivityLog, getResourceEmbedToken, manageDeviceToken, createUserFav, exportResource, getUserFavorites, getAllPbiResources, audioTranscription, deleteUserFavorites, searchActivityLog, createActivityLog, notificationActivity, notificationAllActivity, getNameandLogo, deleteDeviceToken, searchActiveDevices  } from "./manageClient";
