import React from "react";
import { useSelector, useDispatch } from "react-redux";
import NavbarMenu from "../../../components/clientAdmin/NavbarMenu";
import SidebarMenu from "../../../components/clientAdmin/SidebarMenu";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  setMenuOpenClose,
} from "../../../store/features/generalSlice";
export default function MainLayout({ children }) {
  const general = useSelector((state) => state.general);
  const burggerMenuClassName =
    (general?.menuOpenClose === true ? "right off" : "right") +
    (general?.burggerMenuOpenClose === true ? " pull" : "");
  return (
    <section className="main-wrap" style={{ '--primary-color': general?.primaryColor, '--secondary-color' : general?.secondaryColor }}>
      <SidebarMenu />
      <div className={burggerMenuClassName}>
        <NavbarMenu />

        <main>{children}</main>
      </div>
    </section>
  );
}
