import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createPbiConfig } from "../../../services";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import FormErrorMessage from "../../../components/clientAdmin/FormErrorMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientPbiConfig({ instance }) {
  const { clientId } = useParams();
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [formValues, setFormValues] = useState(false);

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  const saveClientPbiConfig = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    createPbiConfig(clientId, params, user)
      .then((res) => {
        let succesMessage = "Client pbi config created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          console.log(error);
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };
  const ValidationSchema = Yup.object().shape({
    azureTenantId: Yup.string().required("'azureTenantId' must not be empty"),
    appId: Yup.string().required(
      "'appId' must not be empty"
    ),
    appSecret: Yup.string().required("'appSecret' must not be empty."),
    appSecretExpiry: Yup.string().required(
      "'appSecretExpiry' must not be empty"
    ),
  });
  useEffect(() => {
    getUserData();
    setShowLoader(false);
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client Pbi Config Creation</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  azureTenantId: formValues?.azureTenantId ? formValues.azureTenantId : "",
                  appId: formValues?.appId ? formValues.appId : "",
                  appSecret: formValues?.appSecret ? formValues.appSecret : "",
                  appSecretExpiry: formValues?.appSecretExpiry ? formValues.appSecretExpiry : "",
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  setFormValues(values);
                  saveClientPbiConfig(JSON.stringify(values, null, 2));
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Azure Tenant ID
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="azureTenantId"
                                      className="form-control"
                                    />
                                    {errors.azureTenantId && touched.azureTenantId ? (
                                      <FormErrorMessage message={errors.azureTenantId} />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    App ID
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="password"
                                      name="appId"
                                      className="form-control"
                                    />
                                    {errors.appId && touched.appId ? (
                                      <FormErrorMessage message={errors.appId} />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    App Secret
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="password"
                                      name="appSecret"
                                      className="form-control"
                                    />
                                    {errors.appSecret && touched.appSecret ? (
                                      <FormErrorMessage message={errors.appSecret} />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    App Secret Expiry
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="appSecretExpiry"
                                      className="form-control"
                                    />
                                    {errors.appSecretExpiry && touched.appSecretExpiry ? (
                                      <FormErrorMessage message={errors.appSecretExpiry} />
                                    ) : null}
                                    <span className="info-text">Date format eg:2024-09-09T11:46:42.3327364+00:00  </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client-list`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientPbiConfig;
