import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import moment from "moment";

import {
  getMyNotifications,
  notificationActivity,
  notificationAllActivity,
} from "../../../services/user";
import LoaderComponent from "../../../components/user/LoaderComponent";
import ImagePopup from "../../../components/user/ImagePopup";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "moment-timezone";
import DownloadIcon from "../../../assets/theme/images/download-icon.svg";
import FileIcon from "../../../assets/theme/images/file-icon.svg";
import AttachmentImage from "../../../assets/theme/images/attachment-image-1.jpg";
import SearchBlack from "../../../assets/theme/images/search-black.png";

import MailOpenGreen from "../../../assets/theme/images/mail-open-green.png";
import MailOpen from "../../../assets/theme/images/mail-open.png";
import MailActive from "../../../assets/theme/images/mail-active.png";
import DateIcon from "../../../assets/theme/images/date-icon.png";
import Form from "react-bootstrap/Form";

export default function MyNotifications({ instance }) {
  const imageExtns = ["png", "jpg", "jpeg", "gif" , "PNG", "JPEG", "JPG", "GIF"];
  const docExtns = ["pdf", "doc", "PDF", "DOC"];
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [status, setStatus] = useState(null);
  const [skip, setSkip] = useState(null);
  const [prevSkip, setPrevSkip] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [continuationToken, setContinuationToken] = useState();
  const [unreadCount, setUnreadCount] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "manage notifications" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function startFetching(skipVal = null) {
    const prevSkipval = skipVal - 20;

    
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    const response = await getMyNotifications(
      continuationToken,
      user,
      searchValue,
      formattedStartDate,
      formattedEndDate,
      status,
      skipVal
    ).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/user/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data.notifications);
      setContinuationToken(response.data.continuationToken);
      response.data.skip < response.data.total
        ? setSkip(response.data.skip)
        : setSkip(null);

      setUnreadCount(response.data.unReadCount);
      setShowLoader(false);
      setPrevSkip(prevSkipval);
    }
  }

  const clearSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setStatus(null);
    startFetching();
  };
  const getFileName = (filePath) => {
    let path = filePath.split("/");
    path = path[path.length - 1].split("?");
    return path[0];
  };
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  async function markAsRead(notifId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let ignore = false;
    const response = await notificationActivity(notifId, "Read", user)
      .then(() => {
        setAlertMessage(
          "All notifications have been successfully marked as read."
        );
        startFetching();
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
          navigate("/user/error-page");
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }
  async function markAllAsRead() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let ignore = false;
    const response = await notificationAllActivity("Read", null, user)
      .then(() => {
        setAlertMessage(
          "All notifications have been successfully marked as read."
        );
        startFetching(continuationToken);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
          navigate("/user/error-page");
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }

  const openPopup = (imagePath) => {
    setShowPopup(true);
    setImageUrl(imagePath);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  function notficationRow(item) {
    const extn = item.notification.filePath
      ? getExtension(getFileName(item.notification.filePath))
      : "";
    return (
      <div className="col-md-12" key={item.id}>
        <div
          className={
            item?.activity?.state == "New"
              ? "notifwrap-block read"
              : "notifwrap-block"
          }
        >
          {item?.activity?.state == "New" && (
            <div className="icn-read">
              <a onClick={() => markAsRead(item.activity.id)}>
                <img src={MailActive} alt="" />
              </a>
            </div>
          )}
          {item?.activity?.state != "New" && (
            <div className="icn-read">
              <img src={MailOpen} alt="" />
            </div>
          )}
          <div className="nwb-head">
            <h3>{item.notification.subject}</h3>
            <time>
              <Moment tz="Asia/Kolkata" format="DD MMM YYYY hh:mm a">
                {item.notification.createdAt}
              </Moment>
            </time>
          </div>
          <div className="nwb-body">
            <p>{item.notification.content}</p>
          </div>
          {item.notification.filePath && (
            <div className="nwb-attachments">
              {docExtns.some((item) => extn == item) && (
                <div className="notifattach-files">
                  <div className="nafile-block">
                    <a href={item.notification.filePath}>
                      <div className="nafb-inner">
                        <div className="nafileblock-left">
                          <div className="nafbl-icon">
                            <img src={FileIcon} alt="" />
                          </div>
                          <div className="nafbl-text">
                            <h4>{getFileName(item.notification.filePath)}</h4>
                            <h5>
                              {getExtension(
                                getFileName(item.notification.filePath)
                              )}{" "}
                              File
                            </h5>
                          </div>
                        </div>
                        <div className="nafileblock-right">
                          <p className="nafilebtn-download">
                            <span>Download</span>{" "}
                            <img src={DownloadIcon} alt="" />
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )}
              {imageExtns.some((item) => extn == item) && (
                <div className="notifattach-images">
                  <div className="naimage-block">
                    <a
                      href="#"
                      onClick={() => openPopup(item.notification.filePath)}
                    >
                      <div className="naib-inner">
                        <img src={item.notification.filePath} alt="" />
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2 flex-wrap">
              <h1>Notification</h1>
            </div>

            <div className="main-bg-wraper--2">
              <div className="details-list-wraper">
                {alertMessage && (
                  <div className="alert alert-success" role="alert">
                    <div className="alert-message">{alertMessage}</div>
                  </div>
                )}
                {error && (
                  <div className="alert alert-error" role="alert">
                    <div className="alert-message">{error}</div>
                  </div>
                )}
                <div className="notifications-wrap">
                  <div className="notification-filter">
                    <div className="search">
                      {/* <img src={SearchBlack} alt="" />
                      <input
                        placeholder="Search"
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      /> */}
                    </div>

                    <div className="right-filter">
                      <Form.Select
                        aria-label="Default select example"
                        value={status}
                        selected={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="New">Unread</option>
                        <option value="Read">Read</option>
                      </Form.Select>
                      <div className="date-wrap">
                        <div className="cell">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            placeholder="dd-mm-yyyy"
                            className="dateF"
                          />
                        </div>
                        <span>to</span>
                        <div className="cell">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            placeholder="dd-mm-yyyy"
                            className="dateF"
                          />
                        </div>
                      </div>

                      <button
                        className="btn filter"
                        onClick={() => startFetching()}
                      >
                        Filter
                      </button>
                      <button
                        className="btn clear"
                        onClick={() => clearSearch()}
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  <div className="read-unread">
                    <div className="left-text">
                      {unreadCount > 0 && (
                        <>{unreadCount} Unread Notifications Found</>
                      )}
                    </div>

                    <div className="right-text" onClick={() => markAllAsRead()}>
                      <img src={MailOpenGreen} alt="" /> Mark all as read
                    </div>
                  </div>

                  <div className="row g-4">
                    {data.map((item) => notficationRow(item))}

                    <div className="col-md-12">
                      <nav className="pagination-style-1">
                        <ul className="pagination">
                          {prevSkip >= 0 && (
                            <li className="page-item ">
                              <a
                                className="page-link disabled"
                                href="javascript:void(0);"
                                aria-disabled="true"
                                onClick={() => startFetching(prevSkip)}
                              >
                                <i className="fa-solid fa-arrow-left"></i>{" "}                               
                              </a>
                            </li>
                          )}
                          {skip !== null && (
                            <>
                              <li className="page-item">
                                <a
                                  className={
                                    skip === null
                                      ? "page-link disabled"
                                      : "page-link"
                                  }
                                  href="javascript:void(0);"
                                  aria-disabled="true"
                                  onClick={() => startFetching(skip)}
                                >
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              </li>
                            </>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showPopup && <ImagePopup imageUrl={imageUrl} onClose={closePopup} />}
      </MainLayout>
    </MsalProvider>
  );
}
