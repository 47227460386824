import React from "react";
import { Circles } from "react-loader-spinner";
import LoaderLogo from "../../assets/theme/images/loader-logo.png";

const LoaderComponent = () => {
  return (
    <div className="main-loader fullSize">
      <div className="load-logo">
        <img src={LoaderLogo} alt="" />
      </div>
      <div className="loader"></div>
    </div>
  );
};

export default LoaderComponent;
