import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { activeMenu } from "../../store/features/generalSlice";
import Navtab from "../../views/user/dashboard/navtab";
function SidebarMenu() {
  const dispatch = useDispatch();
  const activeMenu = useSelector((state) => state.general.activeMenu);
  const general = useSelector((state) => state.general);
  useEffect(() => {
    // Initialize ClassyNav
  }, []);
  const burggerMenuClassName =
    (general?.menuOpenClose === true ? "left off" : "left") +
    (general?.burggerMenuOpenClose === true ? " pull" : "") + 
    (general?.fullwidth === true ? " center-full" : ""); 

  return (
    <div className={burggerMenuClassName } >
      <Navtab />
    </div>
  );
}

export default SidebarMenu;
