// ImagePopup.js
import { useState, useEffect } from "react";
import "../../assets/theme/css/ImagePopup.css";
import { Close2, Download2 } from "../../../src/assets/theme/images/icons";

const AlertMessage = ({ alertType, alertMessage }) => {
  console.log(alertType, alertMessage);
  const [showAlertMessage, SetShowAlertMessage] = useState(true);
  function hideAlertMessage() {
    SetShowAlertMessage(false)
  }
  return (
    <>
      {alertType == "success" && showAlertMessage && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <div className="alert-message">{alertMessage}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={()=>hideAlertMessage() }
          ></button>
        </div>
      )}
      {alertType == "error" && showAlertMessage && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <div className="alert-message">{alertMessage}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={()=>hideAlertMessage() }
          ></button>
        </div>
      )}
    </>
  );
};

export default AlertMessage;
