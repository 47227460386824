import {
  routes,
  get,
  post,
  put,
  deleteRequest,
  patchRequest,
  nonExpGet,
  nonExpPost,
  pbiPost,
  nonExpPut,
} from "./index";
export const getClient = (user) => {
  return get(`${"exp/me/"}`, { user });
};

export const searchWorkspaces = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/user/workspaces" + urlParams}`, { user });
};

export const searchPbiResources = (searchValue, workspaceId, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "&name=" + searchValue;
  }
  return get(
    `${
      "exp/client/user/pbiResources/search?workspaceId=" +
      workspaceId +
      urlParams
    }`,
    { user }
  );
};
export const getAllPbiResources = (type, user) => {
  let urlParams = "";
  if (type !== undefined && type !== "") {
    urlParams = "?type=" + type;
  }
  return get(`${"exp/client/user/pbiResources" + urlParams}`, { user });
};

export const getPbiResources = (resourceId, user) => {
  return get(`${"exp/client/user/pbiResource/" + resourceId}`, { user });
};

export const getEmbedToken = (body, user) => {
  return post(`${"exp/client/pbiResources/embedToken"}`, { body, user });
};

export const getResourceEmbedToken = (resourceId, user) => {
  return get(`${"exp/client/pbiResources/" + resourceId + "/embedToken"}`, {
    user,
  });
};

export const pbiResourcesRefresh = (user, body = {}) => {
  return post(`${"exp/client/pbiResources/refresh"}`, { body, user });
};

export const searchClientSecurityGroups = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/user/securityGroups" + urlParams}`, { user });
};

export const searchCreatedNotifications = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/notifications/createdBy" + urlParams}`, { user });
};
export const getMyNotifications = (continuationToken, user, searchValue, formattedStartDate, formattedEndDate, status, skip) => {
  let urlParams = "";
  if (continuationToken !== undefined && continuationToken !== "" && continuationToken !== null) {    
    urlParams = "?continuationToken=" + continuationToken;
  }
  if (searchValue !== undefined && searchValue !== "" && searchValue !== null) {    
    urlParams = urlParams != "" ?  urlParams + "&searchValue=" + searchValue :  "?searchValue=" + searchValue;
  }
  if (formattedStartDate !== undefined && formattedStartDate !== "" && formattedStartDate !== null) {    
    urlParams = urlParams != "" ?  urlParams + "&startDate=" + formattedStartDate :  "?startDate=" + formattedStartDate;
  }
  if (formattedEndDate !== undefined && formattedEndDate !== "" && formattedEndDate !== null) {    
    urlParams = urlParams != "" ?  urlParams + "&endDate=" + formattedEndDate :  "?endDate=" + formattedEndDate;
  }
  if (status !== undefined && status !== "" && status !== null) {    
    urlParams = urlParams != "" ?  urlParams + "&state=" + status :  "?state=" + status;
  }
  if (skip !== undefined && skip !== "" && skip !== null) {    
    urlParams = urlParams != "" ?  urlParams + "&skip=" + skip :  "?skip=" + skip;
  }
  return get(`${"/exp/notifications" + urlParams}`, { user });
};
export const getActivityLog = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/activityLog/" + urlParams}`, { user });
};

export const manageDeviceToken = (body, user) => {
  return post(`${"exp/client/deviceToken"}`, { body, user });
};
export const deleteDeviceToken = (body, user) => {
  return post(`${"exp/client/deviceToken"}`, { body, user });
};
export const createUserFav = (body, user) => {
  return post(`${"exp/client/user/favorites"}`, { body, user });
};
export const exportResource = (resourceId, body, user) => {
  return post(`${"exp/pbiResources/" + resourceId + "/export"}`, {
    body,
    user,
  });
};

export const getUserFavorites = (user) => {
  return get(`${"exp/client/user/favorites"}`, { user });
};
export const deleteUserFavorites = (favId, user) => {
  return deleteRequest(`${"exp/client/user/favorites/" + favId}`, { user });
};

export const notificationActivity = (notifId, state, user) => {
  return patchRequest(`${"exp/notifications/" + notifId + "/" + state}`, {
    user,
  });
};

export const audioTranscription = (body, user) => {
  let contentType =
    "multipart/form-data; boundary=<calculated when request is sent>";
  return post(`${"exp/ai/transcribe"}`, {
    body,
    user,
    contentType,
  });
};

export const searchActivityLog = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/activityLog/search" + urlParams}`, { user });
};

export const searchActiveDevices = (user) => {
  
  return get(`${"exp/client/deviceToken/search" }`, { user });
};

export const createActivityLog = (body, user) => {
  return post(`${"exp/client/activityLog"}`, { body, user });
};
export const notificationAllActivity = (state, body, user) => {
  return patchRequest(`${"exp/notifications/all/"+state}`, { body, user });
};

export const getNameandLogo = (uniqueSubDomain) => {
  let urlParams = "";
  if (uniqueSubDomain !== undefined && uniqueSubDomain !== "") {
    urlParams = "?uniqueSubDomain=" + uniqueSubDomain;
  }
  return get(`${"exp/client/nameAndLogo" + urlParams}`, { });
};
